<template>
  <div class="live-box" ref="livebox">
    <!-- <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>

      <el-breadcrumb-item
        :to="{ path: `/course/${lessonInfo.courseProjectId}`,query:{index:$route.query.index,from: $route.query.from}}">
        课程详情
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{lessonInfo && lessonInfo.name}}</el-breadcrumb-item>
    </el-breadcrumb> -->
    <div style="" class="video-container flex-layout">
      <div class="video-wrapper">
        <!-- 勿删除 -->
        <!-- <video controls id="video" :src="videoSrc" controlsList="nodownload" autoplay muted>您的浏览器不支持 video 标签。</video> -->
        <!-- 勿删除 -->
        <!-- <video v-if="videoType" controls id="my-video" style="width:100%;height:100%" class="video-js"> -->
        <video v-if="videoType" controls id="my-video" style="width:100%;height:100%" class="video-js vjs-default-skin"
          preload="auto" controlsList="nodownload" autoplay muted>
          <source :src="videoSrc" type="application/x-mpegURL" />
        </video>
        <video v-else controls id="video" :src="videoSrc" controlsList="nodownload" autoplay muted></video>
      </div>
      <div class="video-sidebar flex-column">
        <div class="group time">
          <h3>课节时间</h3>
          <p v-if="!courseInfo.lessonsPattern">
            {{`${dayjs(lessonInfo.lessonsBeginTime*1000).format('YYYY-MM-DD HH:mm:ss')} - ${ dayjs(lessonInfo.lessonsEndTime*1000).format('HH:mm:ss')}`}}
          </p>
          <p v-else>{{dayjs(lessonInfo.lessonsBeginTime*1000).format('YYYY-MM-DD')}}</p>
        </div>
        <div class="group introduction">
          <h3>课程介绍</h3>
          <p v-html="(courseInfo && courseInfo.courseExplain) || '暂无简介'"></p>
        </div>
        <!-- 答题功能 暂时停用 勿删 -->
        <!-- <div class="group problem" v-if="showQuestion">
          <h3>答题</h3>
          <p class="stalk">1.学习科学（Learning Science）是由多个学科构成的交叉学科。下列选项与该交叉学科无直接关系的是</p>
          <div class="options">
            <el-radio :disabled="isRadioDis" v-model="radio" label="A">A.脑神经科学</el-radio>
            <el-radio :disabled="isRadioDis" v-model="radio" label="B">B.认知心理学</el-radio>
            <el-radio :disabled="isRadioDis" v-model="radio" label="C">C.教育技术学 </el-radio>
            <el-radio :disabled="isRadioDis" v-model="radio" label="D">D.图书情报学</el-radio>
          </div>
          <el-button type="primary" @click="submit()" :plain="true" :disabled="isBtnDis">{{btnWord}}</el-button>
          <div class="group answer" v-if="isAnswer">
            <p>正确答案：D 回答{{answerIsTrue}}</p>
            <p>答案解析：省略</p>
            <p>考察能力：学情分析能力</p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import videojs from 'video.js'
  import 'videojs-contrib-hls'
  import {
    mapState
  } from "vuex";
  export default {
    data() {
      return {
        options: { //videojs配置项
          autoplay: true, // 设置自动播放
          muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音（Chrome66及以上版本，禁止音视频的自动播放）
          preload: 'auto', // 预加载
          controls: true // 显示播放的控件
        },
        player: null,
        videoSrc: undefined, //视频地址
        videoType: false, // 视频类型是否为 m3u8
        lessonInfo: {}, //课节信息
        courseInfo: {}, //课节所在在课程信息
        interval: undefined, //非m3u8视频计时定时器
        watchStamp: undefined, //本次观看时间戳
        playStamp: null, //本次播放标识
        lastStamp: null, //本次播放的进度
        totalStamp: null, //可拖拽的最大进度
        playTime: null, //视频播放时长
        // 答题功能 暂停使用 勿删 ===========
        // radio: '', //按钮绑定value
        // showQuestion: false, //显隐问题
        // isRadioDis: false, //禁用选项
        // btnWord: '提交', //按钮文字
        // isBtnDis: false, //禁用按钮
        // isPause: true, //视频是否暂停
        // isAnswer: false,
        // answerIsTrue: '正确',
        // 答题功能 暂停使用 勿删 ===========
      };
    },
    computed: {},
    methods: {
      // 创建videeojs播放器
      getVideo(nowPlayVideoUrl) {
        let _this = this;
        let flagTime = 0;
        this.player = videojs('my-video', this.options, function () {
          this.on('play', function () {
            // console.log('正在播放');
            _this.getWatchStamp();
          })
          this.on("seeking", function () {
            console.log("视频跳转中");
          })
          this.on("seeked", function () {
            console.log("视频跳转结束");
          })
          this.on('timeupdate', function (res) {
            console.log('监听进度条拖动', res)
            const currentTime = this.currentTime();

            if (Math.floor(currentTime) - flagTime >= 60) {
              flagTime = Math.floor(currentTime);
              _this.putCountTime();
            }
          })

          this.on('pause', function () {
            // console.log('暂停了');
            _this.putCountTime();
          })
        })
        this.player.src([{
          src: nowPlayVideoUrl, // 地址
          type: 'application/x-mpegURL' // 告诉videojs,这是一个hls流
        }])

      },
      // 获取视频地址
      async getDownloadsUrl() {
        let resData = await this.$Api.Course.getLessonVideoUrl(this.$route.query.id);
        let requestUrl = `${this.getDownloadUrl}${resData.data}&type=5`
        axios({
          url: requestUrl,
          method: "get",
        }).then((res) => {
          console.log('获取视频地址成功',res)
          this.videoType = res.data.data.ext === 'm3u8';
          this.videoSrc = res.data.data.url;

          setTimeout(() => {
            if (this.videoType) this.getVideo(this.videoSrc)
            if (!this.videoType) {
              this.getVideoInfo();
              document.getElementById("video").muted = false;
            }
          }, 0)

        }).catch(function (error) {
          console.log(error);
        });
      },
      // 获取课节信息
      async getLessonInfo() {
        let resData = await this.$Api.Course.getAsnycLive(this.$route.query.id);
        this.lessonInfo = resData.data;
        this.$route.meta.title = this.lessonInfo.name;
        this.$store.commit('breadPageNameChange', this.lessonInfo.name)
        this.getCourseInfo(this.lessonInfo.courseProjectId)
      },
      //获取课程信息
      async getCourseInfo(courseId) {
        let resData = await this.$Api.Course.getCourseInfo(courseId)
        // console.log(resData);
        this.courseInfo = resData.data
      },
      getVideoInfo() {
        let _video = document.getElementById('video')
        // console.log(_video);
        if (!_video) return;

        // 监听视频播放
        _video.addEventListener('play', () => {
          // console.log('play')
          this.getWatchStamp();
          this.interval = setInterval(() => {
            this.putCountTime();
          }, 60 * 1000)
        })

        // 监听视频暂停
        _video.addEventListener('pause', () => {
          // console.log('pause')
          clearInterval(this.interval);
          this.putCountTime();
        })

        _video.addEventListener('seeking',(res)=>{
          console.log('开始拖拽',res)
        })
        _video.addEventListener('seeked',(res)=>{
          console.log('结束拖拽',res)
        })
        //监听视频播放时间 答题功能 (勿删)
        // _video.addEventListener('timeupdate', () => {
        // let _currentTimeCeil = Math.ceil(_video.currentTime)
        // let _currentTimeFloor = Math.floor(_video.currentTime)
        // //分别向上、向下取整时间，保证题目出现视频播放在同一时间
        // if (_currentTimeFloor >= 5) {
        //   this.showQuestion = true;
        // }
        // if (_currentTimeCeil >= 6 && this.isPause) { //给一个isPause否则视频会因为时间未过6秒而被暂停
        //   _video.pause();
        // }
        // })
      },

      // 观看计时
      async putCountTime() {
        let params = {
          lessonId: this.$route.query.id,
          learnTarget: this.playStamp, //播放标识
          progress: this.playTime, //视频播放时长
        }
        let resData = await this.$Api.Course.countTime(params);
        console.log('视频计时', resData)
        try {
          if (resData.code == 1000) {
            console.log('你已开启多个视频，平台不支持多个视频同时播放');
            this.$emit("tips")
          }
        } catch (err) {
          console.log('code为200不处理')
        }
      },
      // 获取本次播放标识
      async getWatchStamp() {
        let params = {
          lessonId: this.$route.query.id,
          platform: 5, // 标识播放平台 5-研修平台
        }
        let resData = await this.$Api.Course.watchStamp(params);
        console.log('获取本次播放标识', resData);
        this.playStamp = resData.data.data.learnTarget;
        this.lastStamp = resData.data.data.lastProgress;
        this.totalStamp = resData.data.data.totalProgress;
      },
      //点击提交 答题功能 暂时停用  ====勿删====
      // submit() {
      //   if (this.radio === '') {
      //     this.$message.error('请选择一个答案');
      //     return
      //   } else {
      //     this.isRadioDis = true;
      //     this.$message({
      //       message: '提交成功',
      //       type: 'success'
      //     });
      //     this.btnWord = '已提交';
      //     this.isBtnDis = true;
      //     this.isAnswer = true;
      //     if (this.radio === 'D') {
      //       this.answerIsTrue = '正确'
      //     } else {
      //       this.answerIsTrue = '错误'
      //     }

      //     this.isPause = false;
      //     document.getElementById('video').play();
      //   }
      // },
      // 页面初始化

      init() {
        this.getLessonInfo();
        this.getDownloadsUrl();
        // this.getVideoInfo();
      }
    },
    // 销毁
    beforeDestroy() {
      if (this.player) {
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture();
        }
        this.player.dispose();
      }
    },
    activated() {
      //勿删除
      // this.init();
      // this.getVideoInfo();
      // window.addEventListener('beforeunload', e => {
      //     this.putCountTime()
      // })
      //勿删除

    },
    mounted() {
      this.init();
    },
    destroyed() {
      clearInterval(this.interval);
    }
  };
</script>

<style lang="less" scoped>
  .live-box {
    // position: absolute;
    // width: 100%;
    // height: 100%;
    // padding: 0;
  }

  .bread-crumb {
    margin: 0px auto;
    width: 1200px;
  }

  .video-container {
    height: calc(100vh - 246px);
  }

  .video-wrapper {
    width: 90%;

    video {
      width: 100%;
      height: 100%;
      // max-width: 1300px;
      background-color: #000;
    }
  }

  .title {
    text-align: center;
    height: 2%;
  }

  .video-sidebar {
    padding: 0 20px;
    width: 20%;
    background-color: #282933;
    flex-shrink: 0;
    height: 100%;

    .group {
      margin-top: 20px;

      &:first-child {
        margin-top: 15px;
      }
    }


    h3 {
      line-height: 36px;
      color: #fff;
      font-size: 16px;
      font-weight: 500;

      &::before {
        content: ' ';
        display: inline-block;
        border-radius: 25%;
        width: 4px;
        height: 20px;
        background-color: #218AB8;
        margin-right: 10px;
        margin-bottom: -5px;
      }
    }

    p {
      color: #fff;
      padding: 0 15px;
    }

    .introduction {
      flex: 1;

      &>p {
        overflow-y: auto;
        max-height: calc(100vh - 450px);

        /*修改滚动条样式*/
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 2px;
        }

        &::-webkit-scrollbar-button {
          height: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: #bfbfbf;
          border-radius: 4px;
        }
      }
    }

    .list /deep/.el-button--primary {
      margin: 20px 15px 0;
      color: #218AB8;
      background-color: transparent;
      border-color: #218AB8;
      padding: 12px 18px;
    }


    .time p {
      font-size: 14px;
      margin: 5px 0 20px;
    }

    .problem {
      .options {
        margin-bottom: 25px;

        /deep/.el-radio {
          display: block;
          margin: 10px 25px;
          color: #fff;
        }
      }

      /deep/.el-button--primary {
        width: 70%;
        display: block;
        margin: 0 auto;
      }

      /deep/.el-button--primary.is-plain {
        color: #fff;
        border-color: #409EFF;
        background-color: #409EFF;

        &:hover {
          background-color: #66b1ff;
          border-color: #66b1ff;
        }
      }
    }

  }


  iframe {
    margin: 0;
    padding: 0;
  }
</style>